:root {
  --font-fallback: serif;
  --shadow-sm: 2px 2px 2px 2px #0006;
  --shadow-md: 4px 4px 6px 2px #0006;
  --border-radius-sm: 5px;
  --border-radius-md: 10px;
  --border-radius-lg: 50px;
  --font-weight--regular: 400;
  --font-weight--semibold: 500;
  --font-weight--bold: 700;
}

html, body {
  font-family: "Open Sans", var(--font-fallback);
  font-size: 16px;
  font-weight: var(--font-weight--regular);
  height: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
}

body > img {
  position: absolute;
  top: 0;
  left: 0;
}

body {
  background-color: var(--background-linear-gradient-3);
  flex: 1;
}

#app {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: scroll;
}

.rPzCVq_xs {
  width: 24px;
  height: 24px;
}

.rPzCVq_sm {
  width: 32px;
  height: 32px;
}

.rPzCVq_md {
  width: 64px;
  height: 64px;
}

.rPzCVq_lg {
  width: 128px;
  height: 128px;
}

.rPzCVq_gray {
  color: var(--color-black-4);
}

.rPzCVq_white {
  color: var(--text-primary);
}

.rPzCVq_loadingIndicator {
  justify-content: center;
  align-items: center;
  display: flex;
}

@keyframes rPzCVq_rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.rPzCVq_loadingIndicator > svg {
  animation: rPzCVq_rotate 1.5s ease-in-out infinite;
}

@keyframes rPzCVq_fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.rPzCVq_pageLoadingIndicator {
  opacity: 0;
  flex: 1;
  justify-content: center;
  align-items: center;
  animation: rPzCVq_fadein 1s .1s forwards;
  display: flex;
}

.xiGXuq_button {
  all: unset;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: var(--border-radius-lg);
  color: var(--color-black-1);
  font-weight: var(--font-weight--semibold);
  grid-template-columns: 1fr auto 1fr;
  place-items: center;
  padding: 8px 16px;
  display: grid;
}

.xiGXuq_button:disabled {
  cursor: not-allowed;
  color: #00000080;
  box-shadow: none;
  background-color: #cccccc80;
  background-image: none;
  border-color: #0000;
}

.xiGXuq_button:focus-visible {
  outline: 3px solid var(--color-active-3);
  outline-offset: 2px;
}

.xiGXuq_left {
  justify-self: start;
  display: flex;
}

.xiGXuq_right {
  justify-self: end;
  display: flex;
}

.xiGXuq_shinyGold {
  background: #fcd469;
  border: 2px solid #fff;
}

.xiGXuq_dimGold {
  background-image: linear-gradient(to right, var(--color-beige-3), var(--color-beige-2));
}

.xiGXuq_purple {
  color: var(--text-primary);
  background: var(--color-active-2);
}

.xiGXuq_transparent {
  border: 3px solid var(--color-beige-3);
  color: var(--text-primary);
}

.xiGXuq_white {
  color: var(--color-black-1);
  background-color: var(--text-primary);
}

.xiGXuq_white:disabled {
  color: var(--color-black-4);
}

.xiGXuq_text {
  color: var(--text-primary);
  box-shadow: none;
  text-decoration: underline;
}

.gfNARG_container {
  width: 100%;
  min-height: 100%;
  background-color: var(--yds-color-background-dialog-overlay);
  color: var(--yds-color-text-default);
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.gfNARG_content {
  box-shadow: var(--yds-shadow-md);
  background-color: var(--yds-color-background);
  width: 100%;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  margin: 8px;
  padding: 24px;
  display: flex;
}

.gfNARG_trainInfoContainer {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  box-shadow: var(--shadow-md);
  z-index: 2;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.gfNARG_trainInfoContent {
  background-color: var(--color-black-2);
  max-width: 400px;
  border-radius: 4px;
  margin: auto 24px 24px;
  padding: 24px;
}

.gfNARG_trainInfoPanels {
  display: flex;
  position: relative;
}

.gfNARG_trainInfoPanels:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: inset 0 0 10px #000;
}

.gfNARG_trainInfoLeftPanel {
  background-color: var(--color-black-3);
  box-shadow: var(--shadow-md);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  display: flex;
}

.gfNARG_trainInfoButtonContainer {
  min-width: 200px;
  margin-bottom: auto;
}

.gfNARG_trainInfoRightPanel {
  background-color: var(--color-black-4);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.gfNARG_trainInfoRightPanelDivider {
  height: 2px;
  background-color: #fff;
}

.gfNARG_trainInfoClock {
  min-width: 48px;
}

.gfNARG_trainInfoText {
  color: #fff;
  font-weight: var(--font-weight--semibold);
  padding: 0 16px;
}

.S3VBmq_infoModalContent {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.S3VBmq_marginTop {
  margin-top: 16px;
}

.S3VBmq_infoModalContent > p {
  align-self: flex-start;
  margin: 6px 0;
}

.S3VBmq_infoModalHeader {
  text-align: center;
  align-self: flex-start;
}

.Q0YSMG_frontpage {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Q0YSMG_header {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Q0YSMG_frontpageButtonContainer {
  min-width: 220px;
}

.Q0YSMG_frontpageButtonContainer > button {
  margin-bottom: 24px;
}

.Q0YSMG_frontpageButton {
  margin-bottom: 16px;
}

.Q0YSMG_infoButton {
  align-self: flex-end;
}

.Q0YSMG_infoText {
  text-align: center;
  max-width: 320px;
  color: #000;
  padding: 24px;
}

.IKB1yW_background {
  color: var(--text-primary);
  background: url("background.33ac1b84.webp") 0 0 / cover;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.IKB1yW_blur {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.IKB1yW_purple {
  background-image: linear-gradient(to bottom, #0000, var(--background-linear-gradient-2) 50%, var(--background-linear-gradient-3));
  flex: 1;
  display: flex;
}

.IKB1yW_white {
  background-image: linear-gradient(to bottom right, #ffffff1a, #ffffffb3, #ffffff80);
  flex: 1;
  display: flex;
}

.IKB1yW_padding {
  flex: 1;
  padding: 0 8px 8px;
  display: flex;
}

.IKB1yW_goldenEdgeS {
  width: 2px;
  background-color: var(--color-beige-3);
  height: 100%;
  box-shadow: 0 0 1px 3px var(--color-beige-2);
  position: absolute;
  top: 16px;
  left: 16px;
}

.IKB1yW_goldenEdge {
  pointer-events: none;
  opacity: .75;
  border: 1px solid var(--color-beige-3);
  border-radius: var(--border-radius-md) var(--border-radius-md) 0 0;
  box-shadow: 0 0 3px 1px var(--color-beige-2), inset 0 0 3px 1px var(--color-beige-2);
  border-bottom: none;
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.rGPH5q_sharp {
  background-color: var(--color-black-1);
  width: 8px;
  height: 22px;
}

.rGPH5q_rounded {
  border-radius: var(--border-radius-sm);
  background-color: var(--color-black-2);
}

.rGPH5q_roundedBottom {
  border-radius: 0 0 var(--border-radius-sm) var(--border-radius-sm);
  background-color: var(--color-black-2);
}

.rGPH5q_shadow {
  box-shadow: var(--shadow-md);
}

.qbNvwa_container {
  width: 100%;
  min-width: 300px;
  max-width: 540px;
  position: relative;
}

.qbNvwa_imageContainer {
  width: 75%;
  margin: 0 auto;
}

.qbNvwa_image {
  object-fit: contain;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 5% 5%;
}

.qbNvwa_textImageContainer {
  z-index: 2;
  position: absolute;
  top: 55%;
}

.qbNvwa_textImage {
  object-fit: contain;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.SHFOZG_container {
  z-index: 1;
  flex-direction: column;
  display: flex;
}

.SHFOZG_holder {
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
}

.SHFOZG_content {
  padding: 12px;
}

.eTYwaW_container {
  box-shadow: var(--shadow-sm);
  border-radius: var(--border-radius-sm);
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.eTYwaW_left {
  background-color: var(--color-black-3);
  max-width: 80px;
}

.eTYwaW_right {
  background-color: var(--color-black-4);
  min-width: 160px;
  max-width: 200px;
}

.eTYwaW_titleContainer {
  background-image: linear-gradient(to bottom right, var(--color-beige-3), var(--color-beige-2), var(--color-beige-1));
  margin-bottom: 4px;
  padding: 6px 12px;
}

.eTYwaW_title {
  color: #000;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.eTYwaW_divider {
  width: 100%;
  height: 1px;
  background: #fff;
}

.eTYwaW_textContainer {
  padding: 6px;
}

.I9d3da_container {
  flex-direction: column;
  margin: 8px;
  display: flex;
}

.I9d3da_imageContainer {
  position: relative;
}

.I9d3da_liveImage {
  min-width: 60px;
}

.I9d3da_episode {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
}

.I9d3da_episodeName {
  font-size: 12px;
  font-weight: var(--font-weight--bold);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.I9d3da_episodeNumber {
  font-size: 32px;
  font-weight: var(--font-weight--bold);
  margin: 0;
  padding: 0;
}

.I9d3da_liveText {
  text-transform: uppercase;
  line-height: 12px;
  font-weight: var(--font-weight--bold);
  color: var(--color-gray--live);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.I9d3da_isLive {
  color: var(--color-green--live);
  text-shadow: 0 0 2px var(--color-green--live);
}

.FdpA5W_resultspage {
  background: linear-gradient(to left, var(--background-linear-gradient-3), var(--background-linear-gradient-4));
  flex-direction: column;
  flex: 1;
  align-items: start;
  display: flex;
}

.FdpA5W_header {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-self: start;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.FdpA5W_scoreList {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.FdpA5W_scoreItem {
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  justify-content: space-between;
  margin-top: -2px;
  padding: 8px 16px;
  display: flex;
}

.FdpA5W_scoreHeader {
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  justify-content: space-between;
  margin: 0;
  padding: 8px 16px;
  display: flex;
}

.FdpA5W_highlightedItem {
  width: 100%;
  color: #000;
  box-sizing: border-box;
  background: #f8de8e;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  justify-content: space-between;
  margin-top: -2px;
  padding: 8px 16px;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

.FdpA5W_edgeHeader {
  font-size: 14px;
}

.FdpA5W_centerHeader {
  font-size: 14px;
  font-weight: var(--font-weight--bold);
}

.FdpA5W_rightCell, .FdpA5W_leftCell {
  font-size: 18px;
}

.FdpA5W_centerCell {
  text-align: center;
  margin: 0 8px;
  font-size: 18px;
}

.FdpA5W_overlay {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: absolute;
}

.FdpA5W_joinButton {
  width: -webkit-min-content;
  width: min-content;
  white-space: nowrap;
}

.AyAAxq_scorepageContainer {
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.AyAAxq_review {
  font-weight: var(--font-weight--bold);
  text-align: center;
  width: 300px;
  color: #000;
  font-size: 20px;
}

.AyAAxq_scoreContainer {
  flex-direction: column;
  flex: 0 0 65vh;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.AyAAxq_headerText {
  text-transform: uppercase;
  font-size: 32px;
}

.AyAAxq_scoreCircle {
  width: 128px;
  height: 128px;
  border-style: solid;
  border-width: 4px;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 64px;
  display: flex;
}

.AyAAxq_scoreReview {
  text-align: center;
  margin-top: 16px;
}

.AyAAxq_scoreFooter {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.AyAAxq_footerButton {
  width: auto;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px 32px;
}

.FxdEnG_ticket {
  width: 300px;
  height: 357px;
  color: #3f3d38;
  background: url("kultainen_lippu_varjolla.e4900e39.webp") 0 0 / contain no-repeat;
  grid-template-rows: 12px 98px 82px 58px 36px 55px;
  align-items: center;
  display: grid;
}

.FxdEnG_header {
  color: #3f3d38;
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 42px;
}

.FxdEnG_score {
  text-align: center;
  font-weight: var(--font-weight--bold);
  font-size: 64px;
  line-height: 100px;
}

.FxdEnG_footer {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: var(--font-weight--bold);
}

.FxdEnG_hashtag {
  text-align: center;
  text-transform: uppercase;
  font-weight: var(--font-weight--bold);
  font-size: 20px;
}

.zbOU4a_separator {
  width: 100%;
  color: #000;
  flex-direction: row;
  align-items: center;
  margin: 16px 0;
  display: flex;
}

.zbOU4a_dash {
  height: 0;
  border-top: 1px dashed #000;
  flex: 1;
}

.zbOU4a_shareScore {
  padding: 0 12px;
}

.jr32UG_ticket {
  width: 120px;
  color: #000;
  margin: 16px 0;
}

.jr32UG_hashtag {
  font-weight: var(--font-weight--bold);
  color: #000;
  font-size: 24px;
}

.jr32UG_text {
  text-align: center;
  color: #000;
  margin: 16px 24px;
}

.jr32UG_link {
  font-weight: var(--font-weight--bold);
  color: #000;
  align-self: center;
}

.HPPTka_gamepage {
  color: var(--color-black-1);
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

._9BnRtW_container {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 0 12px;
  display: flex;
}

@media (max-width: 320px) {
  ._9BnRtW_container {
    padding: 0;
  }
}

._9BnRtW_container > button {
  color: var(--color-black-1);
}

._9BnRtW_signBoardContent {
  background-image: linear-gradient(to bottom, var(--color-beige-3), var(--color-beige-2), var(--color-beige-1));
  border-radius: var(--border-radius-sm);
  flex-direction: column;
  align-items: center;
  margin: 0 2px;
  display: flex;
}

._9BnRtW_signBoard {
  align-self: center;
}

._9BnRtW_signBoardContentTop {
  flex-direction: column;
  align-items: center;
  padding: 12px;
  display: flex;
}

._9BnRtW_signBoardContentBottom {
  color: var(--text-primary);
  padding: 12px;
}

._9BnRtW_header1 {
  font-size: 18px;
  font-weight: var(--font-weight--bold);
  margin: 2px 0;
}

._9BnRtW_header2 {
  font-size: 14px;
  font-weight: var(--font-weight--bold);
  margin: 2px 0;
}

._9BnRtW_header3 {
  font-size: 14px;
  font-weight: var(--font-weight--semibold);
  text-transform: uppercase;
  margin: 0;
}

._9BnRtW_separator {
  height: 1px;
  background-color: var(--text-primary);
  align-self: stretch;
}

.Vmhh8a_container {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.Vmhh8a_optionContainer {
  flex-direction: column;
  display: flex;
}

.Vmhh8a_option {
  margin-bottom: 16px;
}

.Vmhh8a_questionText {
  text-align: center;
  font-weight: var(--font-weight--bold);
  margin: 32px 0;
  font-size: 18px;
}

.Vmhh8a_hintText {
  text-align: center;
  font-weight: var(--font-weight--semibold);
  min-height: 22px;
}

.Vmhh8a_center {
  width: 200px;
  align-self: center;
}

.Vmhh8a_devNext {
  cursor: pointer;
  align-self: center;
  font-size: 8px;
  text-decoration: underline;
}

.Vmhh8a_sectionDescription {
  text-align: center;
  margin: 16px 0;
}

.cX-6HG_devNext {
  cursor: pointer;
  align-self: center;
  font-size: 8px;
  text-decoration: underline;
}

.KnIRtq_imsImage {
  max-width: 100%;
  margin: 8px 0;
}

.R0U6BW_toggleButton {
  min-height: 48px;
}

.m5F6Ja_container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.m5F6Ja_content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.m5F6Ja_nextStepWrapper {
  margin-top: auto;
}

.m5F6Ja_nextButton {
  width: 100%;
}

.m5F6Ja_nextStepText {
  font-weight: var(--font-weight--bold);
  text-align: center;
  width: 75%;
  margin: 16px auto;
  font-size: 14px;
}

.m5F6Ja_ticketTitle {
  color: var(--color-black-1);
  text-transform: uppercase;
  text-align: center;
  margin: auto;
  padding: 5px;
  font-size: 16px;
  font-weight: bolder;
}

.m5F6Ja_currentScoreInfo {
  color: #fff;
  text-align: center;
  margin: auto;
  padding: 5px min(5vw, 25px);
  font-size: 16px;
}

.m5F6Ja_secondaryText {
  color: #fff;
  font-weight: var(--font-weight--bold);
  text-align: center;
  margin: auto;
  padding: 5px;
  font-size: 14px;
}

.m5F6Ja_ripOff {
  color: #fff;
  font-weight: var(--font-weight--bold);
  margin: auto;
  font-size: 18px;
}

@media (max-width: 341px) {
  .m5F6Ja_ticketTitle {
    font-size: 14px;
  }

  .m5F6Ja_secondaryText {
    font-size: 12px;
  }

  .m5F6Ja_currentScoreInfo {
    font-size: 14px;
  }
}

.m5F6Ja_ticketGrid {
  box-sizing: border-box;
  width: 75vw;
  height: 96vw;
  max-width: 500px;
  max-height: 640px;
  background-image: url("kotipeli-kuva-lippu-turkoosi2.9afe8078.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  grid-template-rows: 25fr 21fr 20fr 4fr 14fr;
  padding: min(5vw, 35px) min(7vw, 39px) min(7vw, 50px);
  display: grid;
  position: relative;
}

.rFE_la_timeEndedHeader {
  background: linear-gradient(135deg, var(--color-active-1), var(--color-active-3));
  color: #fff;
  padding: 16px;
  font-size: 20px;
}

.PZL34G_container {
  color: var(--color-black-1);
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PZL34G_container > button {
  color: var(--color-black-1);
}

._0CH-cq_answer {
  color: #000;
  border-radius: var(--border-radius-md);
  text-align: center;
  font-weight: var(--font-weight--semibold);
  align-self: stretch;
  padding: 8px 12px;
}

._0CH-cq_correctAnswer {
  color: #fff;
  border: 1px solid var(--color-beige-3);
}

._0CH-cq_yourCorrectAnswer {
  color: #000;
  background: #ffdc81;
}

._0CH-cq_incorrectAnswer {
  background: #eaeaea;
}

._0CH-cq_answersContainer {
  flex-wrap: wrap;
  align-self: stretch;
  justify-items: stretch;
  gap: 16px;
  margin: 12px 0;
  display: flex;
}

._0CH-cq_answerContainer {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

._0CH-cq_answerHeader {
  font-weight: var(--font-weight--bold);
  text-align: center;
  white-space: nowrap;
  gap: 8px;
  margin-bottom: 12px;
  display: flex;
}

._0CH-cq_questionContainer {
  border: 2px solid var(--color-beige-2);
  border-radius: var(--border-radius-md);
  background: none;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  padding: 12px 16px;
  display: flex;
}

._0CH-cq_pageHeaderText {
  text-align: center;
  text-transform: uppercase;
  margin: 8px 0 0;
  font-size: 14px;
}

._0CH-cq_page {
  background: linear-gradient(to left, var(--background-linear-gradient-3), var(--background-linear-gradient-4));
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 12px;
  display: flex;
}

._0CH-cq_nextPageButton {
  text-transform: uppercase;
  margin: 12px 0;
}

.X4jaeG_gameIsOnHeader {
  background: linear-gradient(135deg, var(--color-active-2), var(--color-active-3));
  color: #000;
  padding: 16px;
  font-size: 20px;
}

.p6Xh2W_container {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
}

.p6Xh2W_container > h1 {
  max-width: 300px;
  text-align: center;
  color: var(--color-black-1);
}

.p6Xh2W_container > p {
  color: #fff;
  max-width: 300px;
  width: 80%;
  text-align: center;
  color: var(--color-black-1);
  margin: 12px 0;
}

.p6Xh2W_backButton {
  margin-top: auto;
  margin-bottom: 16px;
}

.p6Xh2W_nextButton {
  max-width: 300px;
  width: 75%;
}

._8-m1ba_devtools {
  width: 100%;
  background-color: #000;
  flex-direction: row;
  align-items: center;
  display: flex;
}

._8-m1ba_devtoolItem {
  margin: 4px 2px;
}

.JvZ14q_loginPlease {
  text-align: center;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  padding: 32px;
  font-size: 24px;
  display: flex;
}

.JvZ14q_arrow {
  background: url("arrow.2084de65.png") 100% 0 / 50% 95% no-repeat;
  flex: 1 0;
}

.JvZ14q_text {
  flex: none;
}

.JvZ14q_blank {
  flex: 1 0;
}

:root {
  --yds-color-background: #fff;
  --yds-color-background-variant: #f8f9fa;
  --yds-color-background-control: #00000069;
  --yds-color-background-control-disabled: #00000026;
  --yds-color-background-dialog: #fff;
  --yds-color-background-dialog-overlay: #00000069;
  --yds-color-background-highlight: #e8e9eb;
  --yds-color-background-interactive: #f1f2f4;
  --yds-color-background-info-primary: #9fc8ee;
  --yds-color-background-info-secondary: #f1f2f4;
  --yds-color-background-success: #62f9c2;
  --yds-color-background-warning: #ffce8b;
  --yds-color-background-error: #f99fb5;
  --yds-color-background-input: #fff;
  --yds-color-text-default: #131415;
  --yds-color-text-negative: #f8f9fa;
  --yds-color-text-input-placeholder: #4a4f54;
  --yds-color-text-high-emphasis: #000000c9;
  --yds-color-text-medium-emphasis: #000000b3;
  --yds-color-text-disabled: #0000004a;
  --yds-color-action-primary: #131415;
  --yds-color-action-primary-variant: #000000b3;
  --yds-color-action-secondary: #0000000d;
  --yds-color-action-secondary-variant: #00000017;
  --yds-color-action-disabled: #00000026;
  --yds-color-feedback-highlight: #009cb5;
  --yds-color-feedback-error: #d10b3d;
  --yds-color-feedback-warning: #f1722a;
  --yds-color-feedback-success: #058657;
  --yds-color-feedback-live: #e90e43;
  --yds-color-border: #131415;
  --yds-color-border-disabled: #0000004a;
  --yds-color-border-separator: #e8e9eb;
  --yds-color-border-primary-focus: #009cb5;
  --yds-color-border-secondary-focus: #131415;
  --yds-color-border-focus: #131415;
}

:root {
  --yds-shadow-sm: 0 0 8px 0 #00000026;
  --yds-shadow-md: 0 0 16px 0 #00000026;
  --yds-shadow-lg: 0 0 24px 0 #00000069;
}

.WXqopW_app {
  color: var(--text-primary);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.NRD4iG_container {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.NRD4iG_header {
  text-align: center;
  margin-top: 64px;
}

.NRD4iG_link {
  color: var(--text-primary);
  font-size: 18px;
}

/*# sourceMappingURL=index.e78b2558.css.map */

:root {
  --font-fallback: serif;
  --shadow-sm: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
  --shadow-md: 4px 4px 6px 2px rgba(0, 0, 0, 0.4);

  --border-radius-sm: 5px;
  --border-radius-md: 10px;
  --border-radius-lg: 50px;

  --font-weight--regular: 400;
  --font-weight--semibold: 500;
  --font-weight--bold: 700;
}

/* Remove browser default margins */
html,
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", var(--font-fallback);
  font-size: 16px;
  font-weight: var(--font-weight--regular);
  height: 100%;
  overflow: hidden;
}

/* Hide <img> elements created by Analytics SDK */
body>img {
  position: absolute;
  top: 0;
  left: 0;
}

body {
  background-color: var(--background-linear-gradient-3);
  flex: 1;
}

/* Make the game content to expand vertically */
#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}

/**
 * Yle Design System
 * v0.4.0
 */

:root {
  --yds-shadow-sm: 0 0 8px 0 rgba(0, 0, 0, 0.15);
  --yds-shadow-md: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  --yds-shadow-lg: 0 0 24px 0 rgba(0, 0, 0, 0.41);
}

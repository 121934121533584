/**
 * Yle Design System
 * v0.4.0
 */

:root {
  --yds-color-background: #ffffff;
  --yds-color-background-variant: #f8f9fa;
  --yds-color-background-control: rgba(0, 0, 0, 0.41);
  --yds-color-background-control-disabled: rgba(0, 0, 0, 0.15);
  --yds-color-background-dialog: #ffffff;
  --yds-color-background-dialog-overlay: rgba(0, 0, 0, 0.41);
  --yds-color-background-highlight: #e8e9eb;
  --yds-color-background-interactive: #f1f2f4;
  --yds-color-background-info-primary: #9fc8ee;
  --yds-color-background-info-secondary: #f1f2f4;
  --yds-color-background-success: #62f9c2;
  --yds-color-background-warning: #ffce8b;
  --yds-color-background-error: #f99fb5;
  --yds-color-background-input: #ffffff;
  --yds-color-text-default: #131415;
  --yds-color-text-negative: #f8f9fa;
  --yds-color-text-input-placeholder: #4a4f54;
  --yds-color-text-high-emphasis: rgba(0, 0, 0, 0.79);
  --yds-color-text-medium-emphasis: rgba(0, 0, 0, 0.7);
  --yds-color-text-disabled: rgba(0, 0, 0, 0.29);
  --yds-color-action-primary: #131415;
  --yds-color-action-primary-variant: rgba(0, 0, 0, 0.7);
  --yds-color-action-secondary: rgba(0, 0, 0, 0.05);
  --yds-color-action-secondary-variant: rgba(0, 0, 0, 0.09);
  --yds-color-action-disabled: rgba(0, 0, 0, 0.15);
  --yds-color-feedback-highlight: #009cb5;
  --yds-color-feedback-error: #d10b3d;
  --yds-color-feedback-warning: #f1722a;
  --yds-color-feedback-success: #058657;
  --yds-color-feedback-live: #e90e43;
  --yds-color-border: #131415;
  --yds-color-border-disabled: rgba(0, 0, 0, 0.29);
  --yds-color-border-separator: #e8e9eb;
  --yds-color-border-primary-focus: #009cb5;
  --yds-color-border-secondary-focus: #131415;
  --yds-color-border-focus: #131415;
}
